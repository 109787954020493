<template>
  <div class="product">
    <section class="hero is-medium product-img">
      <div class="hero-body">
        <div class="container">
          <h1 class="product-title">{{ $t('navi.product') }}</h1>
        </div>
      </div>
    </section>

    <div class="inspection">
      <div class="container">
        <h5 class="header-title">{{ $t('product.inspectionItem') }}</h5>
        <div class="tabs">
          <ul>
            <li :class="{ 'is-active': tab === 'warper'}" @click="tab = 'warper'">{{ $t('product.warperInspection') }}</li>
            <li :class="{ 'is-active': tab === 'glass'}" @click="tab = 'glass'">{{ $t('product.glassFabricInspection') }}</li>
            <li :class="{ 'is-active': tab === 'prepreg'}" @click="tab = 'prepreg'">{{ $t('product.prepregInspection') }}</li>
            <li :class="{ 'is-active': tab === 'ccl'}" @click="tab = 'ccl'">{{ $t('product.cclInspection') }}</li>
            <li :class="{ 'is-active': tab === 'customize'}" @click="tab = 'customize'">{{ $t('product.customizeService') }}</li>
          </ul>
        </div>


        <div class="inspection-content" v-show="tab === 'warper'">
          <h4 class="content-title">{{ $t('product.warperInspection') }} <span>Warper Inspection</span></h4>
          <div class="columns">
            <div class="column">
              <p class="content-txt">{{ $t('product.warperTxt1') }}</p>
            </div>
            <div class="column">
              <ul class="inspection-descri">
                <li>{{ $t('product.warperTxt2') }}</li>
                <li>{{ $t('product.warperTxt3') }}</li>
              </ul>
            </div>
          </div>
          <p class="defeat-type"><span></span>{{ $t('product.warperTxt4') }}</p>
          <div class="defeat-imgs">
            <div class="columns is-multiline is-mobile">
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_warper_1.jpg" alt="">
                  <p class="img-tag">{{ $t('product.warperTxt5') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_warper_2.jpg" alt="">
                  <p class="img-tag">{{ $t('product.warperTxt5') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_warper_3.jpg" alt="">
                  <p class="img-tag">{{ $t('product.warperTxt5') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_warper_4.jpg" alt="">
                  <p class="img-tag">{{ $t('product.warperTxt5') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_warper_5.jpg" alt="">
                  <p class="img-tag">{{ $t('product.warperTxt5') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_warper_6.jpg" alt="">
                  <p class="img-tag">{{ $t('product.warperTxt5') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_warper_7.jpg" alt="">
                  <p class="img-tag">{{ $t('product.warperTxt5') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_warper_8.jpg" alt="">
                  <p class="img-tag">{{ $t('product.warperTxt5') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_warper_9.jpg" alt="">
                  <p class="img-tag">{{ $t('product.warperTxt5') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_warper_10.jpg" alt="">
                  <p class="img-tag">{{ $t('product.warperTxt5') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_warper_11.jpg" alt="">
                  <p class="img-tag">{{ $t('product.warperTxt5') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_warper_12.jpg" alt="">
                  <p class="img-tag">{{ $t('product.warperTxt5') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="inspection-content" v-show="tab === 'glass'">
          <h4 class="content-title">{{ $t('product.glassFabricInspection') }} <span>Glass Fabric Inspection</span></h4>
          <div class="columns">
            <div class="column">
              <p class="content-txt">{{ $t('product.glassTxt1') }}</p>
            </div>
            <div class="column">
              <ul class="inspection-descri">
                <li>{{ $t('product.glassTxt2') }}</li>
                <li>{{ $t('product.glassTxt3') }}</li>
              </ul>
            </div>
          </div>
          <p class="defeat-type"><span></span>{{ $t('product.glassTxt4') }}</p>
          <div class="defeat-imgs">
            <div class="columns is-multiline is-mobile">
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_FGF_1.jpg" alt="">
                  <p class="img-tag">{{ $t('product.glassTxt5') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_FGF_2.jpg" alt="">
                  <p class="img-tag">{{ $t('product.glassTxt6') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_FGF_3.jpg" alt="">
                  <p class="img-tag">{{ $t('product.glassTxt6') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_FGF_4.jpg" alt="">
                  <p class="img-tag">{{ $t('product.glassTxt7') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_FGF_5.jpg" alt="">
                  <p class="img-tag">{{ $t('product.glassTxt8') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_FGF_6.jpg" alt="">
                  <p class="img-tag">{{ $t('product.glassTxt9') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_FGF_7.jpg" alt="">
                  <p class="img-tag">{{ $t('product.glassTxt10') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_FGF_8.jpg" alt="">
                  <p class="img-tag">{{ $t('product.glassTxt11') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_FGF_9.jpg" alt="">
                  <p class="img-tag">{{ $t('product.glassTxt12') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_FGF_10.jpg" alt="">
                  <p class="img-tag">{{ $t('product.glassTxt13') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_FGF_11.jpg" alt="">
                  <p class="img-tag">{{ $t('product.glassTxt14') }}</p>
                </div>
              </div>
            </div>
          </div>
          <p class="defeat-type"><span></span>{{ $t('product.glassTxt15') }}</p>
          <div class="defeat-imgs">
            <div class="columns is-multiline is-mobile">
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_FGF_12.jpg" alt="">
                  <p class="img-tag">{{ $t('product.glassTxt16') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_FGF_13.jpg" alt="">
                  <p class="img-tag">{{ $t('product.glassTxt6') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_FGF_14.jpg" alt="">
                  <p class="img-tag">{{ $t('product.glassTxt16') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_FGF_15.jpg" alt="">
                  <p class="img-tag">{{ $t('product.glassTxt16') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_FGF_16.jpg" alt="">
                  <p class="img-tag">{{ $t('product.glassTxt16') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_FGF_17.jpg" alt="">
                  <p class="img-tag">{{ $t('product.glassTxt16') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="inspection-content" v-show="tab === 'prepreg'">
          <h4 class="content-title">{{ $t('product.prepregInspection') }} <span>Prepreg Inspection</span></h4>
          <div class="columns">
            <div class="column">
              <p class="content-txt">{{ $t('product.prepregTxt1') }}</p>
            </div>
            <div class="column">
              <ul class="inspection-descri">
                <li>{{ $t('product.prepregTxt2') }}</li>
                <li>{{ $t('product.prepregTxt3') }}</li>
              </ul>
            </div>
          </div>
          <p class="defeat-type"><span></span>{{ $t('product.prepregTxt4') }}</p>
          <div class="defeat-imgs">
            <div class="columns is-multiline is-mobile">
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_PP_1.jpg" alt="">
                  <p class="img-tag">{{ $t('product.glassTxt16') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_PP_2.jpg" alt="">
                  <p class="img-tag">{{ $t('product.glassTxt16') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_PP_3.jpg" alt="">
                  <p class="img-tag">{{ $t('product.prepregTxt5') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_PP_4.jpg" alt="">
                  <p class="img-tag">{{ $t('product.prepregTxt6') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_PP_5.jpg" alt="">
                  <p class="img-tag">{{ $t('product.prepregTxt8') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_PP_6.jpg" alt="">
                  <p class="img-tag">{{ $t('product.prepregTxt7') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_PP_7.jpg" alt="">
                  <p class="img-tag">{{ $t('product.prepregTxt6') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_PP_8.jpg" alt="">
                  <p class="img-tag">{{ $t('product.prepregTxt8') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_PP_9.jpg" alt="">
                  <p class="img-tag">{{ $t('product.prepregTxt9') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_PP_10.jpg" alt="">
                  <p class="img-tag">{{ $t('product.prepregTxt9') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_PP_11.jpg" alt="">
                  <p class="img-tag">{{ $t('product.prepregTxt10') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_PP_12.jpg" alt="">
                  <p class="img-tag">{{ $t('product.prepregTxt10') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_PP_13.jpg" alt="">
                  <p class="img-tag">{{ $t('product.prepregTxt11') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_PP_14.jpg" alt="">
                  <p class="img-tag">{{ $t('product.prepregTxt11') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="inspection-content" v-show="tab === 'ccl'">
          <h4 class="content-title">{{ $t('product.cclInspection') }} <span>CCL Inspection</span></h4>
          <div class="columns">
            <div class="column">
              <p class="content-txt">{{ $t('product.cclTxt1') }}</p>
            </div>
            <div class="column">
              <ul class="inspection-descri">
                <li>{{ $t('product.cclTxt2') }}</li>
                <li>{{ $t('product.cclTxt3') }}</li>
              </ul>
            </div>
          </div>
          <p class="defeat-type"><span></span>{{ $t('product.cclTxt4') }}</p>
          <div class="defeat-imgs">
            <div class="columns is-multiline is-mobile">
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_CCL_1.jpg" alt="">
                  <p class="img-tag">{{ $t('product.cclTxt5') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_CCL_2.jpg" alt="">
                  <p class="img-tag">{{ $t('product.prepregTxt7') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_CCL_3.jpg" alt="">
                  <p class="img-tag">{{ $t('product.cclTxt6') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_CCL_4.jpg" alt="">
                  <p class="img-tag">{{ $t('product.cclTxt7') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_CCL_5.jpg" alt="">
                  <p class="img-tag">{{ $t('product.cclTxt8') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_CCL_6.jpg" alt="">
                  <p class="img-tag">{{ $t('product.prepregTxt8') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_CCL_7.jpg" alt="">
                  <p class="img-tag">{{ $t('product.cclTxt9') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_CCL_8.jpg" alt="">
                  <p class="img-tag">{{ $t('product.cclTxt10') }}</p>
                </div>
              </div>
            </div>
          </div>
          <p class="defeat-type"><span></span>{{ $t('product.cclTxt11') }}</p>
          <div class="defeat-imgs">
            <div class="columns is-multiline is-mobile">
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_CCL_9.jpg" alt="">
                  <p class="img-tag">{{ $t('product.cclTxt12') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_CCL_10.jpg" alt="">
                  <p class="img-tag">{{ $t('product.cclTxt13') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_CCL_11.jpg" alt="">
                  <p class="img-tag">{{ $t('product.cclTxt14') }}</p>
                </div>
              </div>
              <div class="column is-2-desktop is-3-tablet is-half-mobile">
                <div class="defeat-img">
                  <img src="@/images/defeatimage/img_product_CCL_12.jpg" alt="">
                  <p class="img-tag">{{ $t('product.cclTxt15') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="inspection-content customize" v-show="tab === 'customize'">
          <h4 class="content-title">{{ $t('product.customizeService') }} <span>Customize Service</span></h4>
          <div class="columns">
            <div class="column">
              <p class="content-txt">{{ $t('product.customizeTxt1') }}</p>
              <router-link :to="'/contact'"><div class="btn-see-more">{{ $t('navi.contact') }}</div></router-link>
              <img class="customize-img-mobile" src="@/images/defeatimage/img_product_mobile_customer.jpg" alt="">
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Product',
  data: () => ({
    tab: 'warper'
  }),
  mounted () {
    this.$nextTick(() => {
      if (this.$route.query.tab) {
        this.tab = this.$route.query.tab;
      }
    })
  },
}
</script>

<style lang="sass" scoped>
.product
  .product-img
    min-height: 470px
    background-image: url('../images/img_product_main.png')
    background-position: center center
    background-repeat: no-repeat
    background-size: cover
  .hero-body
    .container
      .product-title
        color: #ffffff
  .content-title
    border-left: 5px solid #005cb9
    padding-left: 8px
  .inspection
    margin: 80px auto 160px
  .tabs
    ul
      border: none
      li
        padding: 5px
        margin: 0 20px
        border-bottom: 2px solid transparent
        opacity: 0.5
        &:hover
          border-bottom: 2px solid #005cb9
      .is-active
        border-bottom: 2px solid #005cb9
        opacity: 1
  .header-title,
    min-width: max-content
    margin-bottom: 10px
  .inspection-content
    .inspection-descri
      li
        list-style: disc
        list-style-position: inside
    .defeat-type
      display: inline-block
      margin: 40px 0.75rem 15px
      border-bottom: 5px solid #e5e5e5
      span
        display: inline-block
        padding: 3px
        margin: 3px 16px 3px 0
        border-radius: 100%
        background-color: #000
    .defeat-imgs
      .defeat-img
        max-width: 160px
        margin: 0 auto
        border-top: 5px solid #808080
        border-bottom: 5px solid #808080
        .img-tag
          max-width: 160px
          height: 33px
          margin-top: -6px
          padding-top: 5px
          background-color: #d9d9d9
          text-align: center
  .customize
    min-height: 400px
    background-image: url('../images/defeatimage/img_product_customer.png')
    background-position: center center
    background-repeat: no-repeat
    background-size: cover
  .customize-img-mobile
    opacity: 0
  .btn-see-more
    display: inline-block
    padding: 10px 13px
    margin-top: 8px
    color: #ffffff
    background-color: #005cb9

@media (max-width: 768px)
  .product
    .columns
      margin: 0
    .product-img
      background-position: -600px center
      .hero-body
        display: flex
        align-items: center
    .content-title
      span
        display: block
    .tabs
      position: sticky
      top: 52px
      background-color: #ffffff
    .customize
      background-image: none
    .customize-img-mobile
      margin-top: 15px
      opacity: 100%
</style>
